import React, { useState, useEffect } from "react";
import { BigNumber, Contract, ethers } from "ethers";
import "./App.css";
import { Helmet } from 'react-helmet';

import contractABI from "./abi.json"; // Importa l'ABI dal tuo file "abi.js"

const contractAddress = "0xcbFe75539583400C03DF5dd64ed5cFCb10b57B3E"; //contratto su Scroll produzione
//const contractAddress = "0x7c8674FF99Cb8a92cC88DD04B22762Da1E7e83A4"; //contratto su Scroll Produzione


function App() {
  const [provider, setProvider] = useState(null);
  const [lotteryContract, setLotteryContract] = useState(null);
  const [account, setAccount] = useState(null);
  const [jackpot, setJackpot] = useState(0);
  const [participantsCount, setParticipantsCount] = useState(0);
  const [message, setMessage] = useState("");
  const [winner, setWinner] = useState(null);

  //leggo gli emit dallo smart contract
const setupEventListeners = () => {
if (lotteryContract){
    
      lotteryContract.on("WinnerIs", (winnerAddress) => {
      setWinner(winnerAddress);
      console.log ("emit arrivato : " );
    });
  }
    
  
};
  async function connectToEthereum() {
    if (window.ethereum) {
      try {
        //gestisce la connessione a metamask
        const accounts = await window.ethereum.request({method: "eth_requestAccounts",});
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();  
        const connectedAccount =  await signer.getAddress();
        const lottery = new ethers.Contract(contractAddress,contractABI,provider);

         //aggiorna i dati 
        setAccount(connectedAccount);
        setLotteryContract(lottery);
        updateJackpot();
        updateParticipantsCount();
        setupEventListeners(); // Aggiungi questa riga per configurare l'ascolto degli eventi
         
      } catch (error) {
        setMessage("WALLET ERROR " + error.message);
      }
    } else {
      setMessage("WALLET NOT FOUND");
    }
  }
//---------------------------------
  useEffect(() => {
  
    const interval = setInterval(() => {
      updateData();
      setupEventListeners();
    }, 1000); // Aggiorna ogni minuto (puoi regolare l'intervallo a seconda delle tue esigenze)
    
    return () => {clearInterval(interval); // Pulisce l'intervallo quando il componente si smonta
    if (lotteryContract) {                //serve per resettare il listener degli emit
      lotteryContract.removeAllListeners();
    }
  }
  }, [connectToEthereum,  setupEventListeners,lotteryContract]);

  //------------------------------

 
 //aggiorna i dati 
  const updateData = async () => {
    await updateJackpot();
    await updateParticipantsCount();
  };



  const updateJackpot = async () => {
    if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    //  const provider = new ethers.providers.JsonRpcProvider("https://rpc.scroll.io/")
      const contract = new ethers.Contract(contractAddress,contractABI,provider);
      try {
        const data = await contract.jackpot();
        let jackpot = parseInt(BigNumber.from(data)._hex);
        jackpot = jackpot / Math.pow(10, 18);
        setJackpot(jackpot);
   //     console.log("Jackpot: ", jackpot);
      } catch (err) {
        console.log("Error: ", err);
      }
    }
  };

  const buyTicket = async () => {
    const accounts = await window.ethereum.request({method: "eth_requestAccounts",});
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();  
    const connectedAccount =  await signer.getAddress();
    const contract= new ethers.Contract(contractAddress,contractABI,provider);
   
   // setAccount(connectedAccount);
   // setLotteryContract(contract);
   
    try {
      // Verifica che sia stato inizializzato il contratto della lotteria
      if (!contract) {
        setMessage("Il contratto non è stato inizializzato correttamente.");
        return;
      }
      // Calcola il valore in Wei per 0,01 ether
      const signer =provider.getSigner();
      const contractSigner= contract.connect(signer);
      const etherValue = ethers.utils.parseEther("0.02");
      // Invia una transazione per acquistare il biglietto
      const tx = await contractSigner.enter({
      value: etherValue,
      });
     
      await tx.wait();// Attendi che la transazione venga elaborata
      setMessage("Ticket purchased successfully!");
     // updateJackpot(); // Aggiorna il montepremi
      //updateParticipantsCount(); // Aggiorna il numero di partecipanti
    } catch (error) {
      setMessage("Error while purchasing the ticket: " + error);
    }
  };

  const updateParticipantsCount = async () => {
    if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
  //  const provider = new ethers.providers.JsonRpcProvider("https://rpc.scroll.io/")
      const contract = new ethers.Contract(contractAddress,contractABI,provider);
      // setLotteryContract();
      try {
        const cont = await contract.GetNumberPlayers();
        setParticipantsCount(parseInt(BigNumber.from(cont)._hex));
        //let b = BigNumber.from("10x0f4249")
        //console.log('Players: ', parseInt(BigNumber.from(cont)._hex) )
       // console.log("players " + cont);
      } catch (err) {
        console.log("Error: ", err);
      }
    }



  };

  return (
    <><div>
      <Helmet>
        <title>ClavusONE OnChain Lottery</title>
        <meta name="description" content="Decentralized Lottery gaming platform" />
        <meta name="keywords" content="lottery, blockchain lottery,gaming, blockchain, smartcontract, smart contract " />
        <meta name="author" content="clavusone" />
        <meta property="og:title" content="BlockChain Lottery" />
        <meta property="og:description" content="This is a description of my page" />
        <meta property="og:image" content="http://clavusone.io" />
        <meta property="og:url" content="http://clavusone.io" />
      </Helmet>
      
    </div><div className="container_top">
        <header className="top-bar">
          <div className="logo">
            <h1 className="web3-title">ClavusONE Gaming</h1>
          </div>
          
        </header>
        <div className="container">
          <p className="web3-info">CONNECTO TO SCROLL L2 and BRIDGE YOUR ETH</p>
          <a href="https://scroll.io" img src="SCROLL_Logo.jpg">
          </a>
          <img src="SCROLL_Logo.jpg" alt="Scroll Network" width="100"></img>

          <p className="web3-info">BRIDGE YOUR ETH </p>  <a href="https://scroll.io/bridge">BRIDGE ETH</a>

          <p className="web3-info">Lottery draws every week on SUNDAY at 20:00 UTC.</p>
          <p className="web3-info">1 CTOK TOKEN FOR EVERY TICKETS</p>
          <p className="web3-info">100 CTOK TOKENs FOR EVERY WINNER</p>
          <p className="web3-info">TICKET FOR 0.02 ETH</p>
          {account ? (
            <div>

              <p className="web3-info">Account Connected: {account}</p>
              <p className="web3-info">Participants: {participantsCount}</p>
              <p className="web3-info">Jackpot: {jackpot} Ether</p>
              <button className="web3-button" onClick={buyTicket}>
                Buy Ticket (0.02 Ether)
              </button>
              {winner && (
                <p className="web3-info">Winner: {winner}</p>
              )}
              
            </div>
          ) : (
            <button className="web3-button connect-button" onClick={connectToEthereum}>
              Connect to MetaMask Wallet
            </button>
          )}
          <p className="web3-info">SCROLL CONTRACT ADDRESS : 0xcbFe75539583400C03DF5dd64ed5cFCb10b57B3E</p>
          <p className="error-message web3-info">{message}</p>
        </div>
        <footer className="footer">
          <div className="copyright">
            <p>&copy; 2023 ClavusONE.io</p>
          </div>
          <div className="info">
            <p className="web3-info">CONTACTS: info@clavusone.io</p>
          </div>
        </footer>
      </div></>
  );
}

export default App;
